// App main
const subscribe = async () => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    const { default: axios } = await import(/* webpackChunkName: "axios" */ 'axios');
    // Create our vue instance
    const vm = new Vue({
        delimiters: ['${', '}'],
        el: "#subscribeNews",
        components: {},
        data() {
            return {
                firstName: '',
                lastName: '',
                email: '',
                message: '',
                sending: false,
                isModalVisible: false,
                result: ''
            }
        },
        methods: {
            showModal() {
                this.isModalVisible = true;
            },
            hideModal() {
                this.isModalVisible = false;
            },
            submitForm() {
                if (this.sending) {
                    return;
                }
                if (this.lastName.length < 3) {
                    window.alert('Пожалуйста, заполните Фамилию');
                    return;
                }
                if (this.firstName.length < 3) {
                    window.alert('Пожалуйста, заполните Имя и Отчество');
                    return;
                }
                if (this.email.length < 3) {
                    window.alert('Пожалуйста, заполните email');
                    return;
                }
                if (this.email.length > 3) {
                    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    if (!re.test(this.email.toLowerCase())) {
                        window.alert('Пожалуйста, введите верный email');
                        return;
                    }
                }
                var data = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    message: this.message
                }
                data[window.Craft.csrfTokenName] = window.Craft.csrfTokenValue;
                var formData = new FormData();

                for (var key in data) {
                    formData.append(key, data[key]);
                }
                this.sending = true
                axios.post('/actions/events-module/news/news-subscribe', formData).then(response => {
                    if (response.data.success) {
                        this.result = 'Спасибо!';
                        setTimeout(() => {
                            this.isModalVisible = false;
                            this.result = '';
                        }, 3000);
                    } else {
                        this.result = 'К сожалению у нас на сайте произошла ошибка. Мы постораемся исправить ее как можно скорее, а пока вы можете <a href="/contacts">связаться с нами по телефону</a>.';
                        setTimeout(() => {
                            this.isModalVisible = false;
                            this.result = '';
                        }, 10000);
                    }
                    this.sending = false;
                }, () => { this.sending = false })
            }
        }
    });

    return vm;
};

subscribe().then((vm) => {
    console.log('Subscribe')
});